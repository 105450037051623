export default class FVendor {
  constructor(
    id,
    vcode ="",
    vname ="",
    avatarImage="",
    fdivisionBean,
    statusActive=true,

    branch =false,
    childOf,

    address1="",
    address2="",
    district1="",
    city1="",
    state1="",
    phone=0,
    countryCode=62,

    zipCode,
    email="",

    currency,
    namaPrshFakturPajak="",
    namaPengusahaKenaPajak="",
    npwp="",
    tanggalPengukuhanPkp=new Date(),

    created,
    modified,
    modifiedBy
  ) {
    this.id = id;
    this.vcode = vcode;
    this.vname = vname;
    this.avatarImage = avatarImage;
    this.statusActive = statusActive;
    this.fdivisionBean = fdivisionBean;

    this.branch =branch;
    this.childOf = childOf;

    this.address1 = address1;
    this.address2 = address2;
    this.district1 = district1;
    this.city1 = city1;
    this.state1 = state1;
    this.phone = phone;
    this.countryCode = countryCode;

    this.zipCode = zipCode;
    this.email = email;

    this.currency = currency;

    this.namaPrshFakturPajak = namaPrshFakturPajak;
    this.namaPengusahaKenaPajak = namaPengusahaKenaPajak;
    this.npwp = npwp;
    this.tanggalPengukuhanPkp = tanggalPengukuhanPkp;



    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
