<template>
  <v-card class="mt-2 ml-2 mr-2">
    <FVendorTable ref="refTabVendorClick"/>
  </v-card>
</template>

<script>
import FVendorTable from "../../components/mitra/vendor/FVendorTable";

export default {
  components: { FVendorTable },
  data() {
    return {
      firstTabTitle: 'SUPPLIER',
      firstTabIcon: 'mdi-factory'
    }
  },
  methods: {
    tabVendorClick(){
      try {
        this.$refs.refTabVendorClick.fetchParent()
      }catch (e) {
        e.toString()
      }

    },

  }
}
</script>

<style scoped>
</style>